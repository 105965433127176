import React, { useState, useMemo, useEffect, useContext, ChangeEvent, MouseEvent } from "react";
import { ChartData } from "chart.js";
import PieChart from "./PieChart";
import "../App.css";
import Navbar from "./Navbar";
import { useData } from './dataContext';

// Define the types for props
interface AssignmentName {
  unit: string;
  assignmentType: string;
  assignmentName: string;
}

interface StudentScores {
  [student: string]: number[];
}

// Define the type for chart data
interface PieChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string;
    borderWidth: number;
  }[];
}

function ButtonComponent() {
  const { data, setData, getMissingAssignments } = useData();
  const [assignmentType, setAssignmentType] = useState<string>('All');
  const [unit, setUnit] = useState<string>('All');
  const [selectedAssignment, setSelectedAssignment] = useState<string | null>(null);

  // Extract assignment names from the first line of the data
  const assignmentNames = useMemo<AssignmentName[]>(() => {
    if (data.length > 0) {
      return data[0].slice(1).map((item: string) => {
        const parts = item.split('-');
        return {
          unit: parts[0],
          assignmentType: parts[1],
          assignmentName: parts[2],
        };
      });
    }
    return [];
  }, [data]);

  const uniqueTypes: string[] = Array.from(new Set(assignmentNames.map((item) => item.assignmentType)));
  const uniqueUnits: string[] = Array.from(new Set(assignmentNames.map((item) => item.unit)));

  const handleAssignmentTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setAssignmentType(event.target.value);
    setSelectedAssignment(null); // Reset selected assignment
  };

  const handleUnitChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setUnit(event.target.value);
    setSelectedAssignment(null); // Reset selected assignment
  };

  const handleAssignmentClick = (assignmentName: string) => {
    setSelectedAssignment(assignmentName);
  };

  // Filter assignment names based on selected assignmentType and unit
  const filteredAssignmentNames = useMemo<string[]>(() => {
    return assignmentNames
      .filter((item) => (assignmentType === 'All' || item.assignmentType === assignmentType) &&
                         (unit === 'All' || item.unit === unit))
      .map((item) => item.assignmentName);
  }, [assignmentType, unit, assignmentNames]);

  // Filter data based on selected assignmentType and unit
  const filteredData = useMemo<StudentScores>(() => {
    if (data.length <= 3) return {}; // No data to process if fewer than 4 rows

    const maxScores = data[2].slice(1).map((score: string) => parseFloat(score) || 0);
    const scoresData = data.slice(3);

    const filteredIndexes = assignmentNames
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => (assignmentType === 'All' || item.assignmentType === assignmentType) &&
                            (unit === 'All' || item.unit === unit))
      .map(({ index }) => index);

    const studentScores: StudentScores = {};

    scoresData.forEach((row: any[]) => {
      const [studentName, ...scores] = row;
      const validScores = scores
        .map((score, index) => {
          if (!filteredIndexes.includes(index)) return null;
          const maxScore = maxScores[index];
          if (score === 'M' || score === 'X' || score === '') return 0;
          const percentage = maxScore > 0 ? (parseFloat(score) / maxScore) * 100 : 0;
          return isNaN(percentage) ? 0 : percentage;
        })
        .filter((score): score is number => score !== null);

      if (validScores.length > 0) {
        studentScores[studentName] = validScores;
      }
    });

    return studentScores;
  }, [data, assignmentType, unit, assignmentNames]);

  // Compute the number of assignments in each grade category
  const assignmentGradeCounts = useMemo(() => {
    const counts = {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      F: 0,
      Missing: 0
    };

    const flattenedScores: number[] = [];

    Object.values(filteredData).forEach((scores) => {
      scores.forEach((score) => flattenedScores.push(score));
    });

    const numA = flattenedScores.filter(score => score >= 90).length;
    const numB = flattenedScores.filter(score => score >= 80 && score < 90).length;
    const numC = flattenedScores.filter(score => score >= 70 && score < 80).length;
    const numD = flattenedScores.filter(score => score >= 65 && score < 70).length;
    const numF = flattenedScores.filter(score => score < 65).length;
    const numNAN = flattenedScores.filter(score => isNaN(score)).length;

    counts.A = numA;
    counts.B = numB;
    counts.C = numC;
    counts.D = numD;
    counts.F = numF;
    counts.Missing = numNAN;

    return counts;
  }, [filteredData]);

  // Ensure chartData is correctly typed and initialized
  const chartData: PieChartData = {
    labels: ['A', 'B', 'C', 'D', 'F', 'Missing'],
    datasets: [
      {
        label: "# of Assignments",
        data: [
          assignmentGradeCounts.A,
          assignmentGradeCounts.B,
          assignmentGradeCounts.C,
          assignmentGradeCounts.D,
          assignmentGradeCounts.F,
          assignmentGradeCounts.Missing
        ],
        backgroundColor: [
          "#40ce00",
          "#a6d96a",
          "#f9dc5c",
          "#fdae61",
          "#d7191c",
          "#FF6347"
        ],
        borderColor: "black",
        borderWidth: 2
      }
    ]
  };

  useEffect(() => {
    // Handle click outside assignment list to reset selectedAssignment
    const handleClickOutside = (event: MouseEvent<Document, globalThis.MouseEvent>) => {
      if (!(event.target as HTMLElement).closest('.Assignment-List') && selectedAssignment) {
        setSelectedAssignment(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside as unknown as EventListener);
    return () => document.removeEventListener('mousedown', handleClickOutside as unknown as EventListener);
  }, [selectedAssignment]);

  return (
    <div className="Analysis-Container" style={{ height: '575px' }}>
      <div className="Selector-Container">
        <label>Select Assignment Type and Unit</label>
        <select id='Assignment-Type' style={{ margin: '10px' }} onChange={handleAssignmentTypeChange} value={assignmentType}>
          <option value="All">All</option>
          {uniqueTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>
        <select id='Unit' style={{ margin: '10px' }} onChange={handleUnitChange} value={unit}>
          <option value="All">All</option>
          {uniqueUnits.map((unit, index) => (
            <option key={index} value={unit}>{unit}</option>
          ))}
        </select>
      </div>
      {/* Use PieChart component and pass chartData */}
      <PieChart chartData={chartData} />

      <div className="Assignment-List" style={{ marginTop: '20px' }}>
        <h2>Assignments List</h2>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {filteredAssignmentNames.length > 0 ? filteredAssignmentNames.map((name, index) => (
            <li
              key={index}
              onClick={() => handleAssignmentClick(name)}
              style={{ cursor: 'pointer', padding: '5px', border: '1px solid black', marginBottom: '5px', textAlign: 'left' }}
            >
              {name}
            </li>
          )) : <li>No Assignments for this combination.</li>}
        </ul>
      </div>
      <Navbar />
    </div>
  );
}

export default ButtonComponent;
