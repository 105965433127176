import {Button} from '@mantine/core';
import axios from 'axios'
import { useState } from 'react';
import Navbar from './Navbar';
// console.log(axios)



const testGet = () => {
  axios.get('http://localhost:3001')
  .then((res) => {
    // console.log('axiox ran')
  })
  .catch((err) => {console.error(err)});
}


function ButtonComponent() {
  const [file, setFile] = useState<any>(null);
  const [status, setStatus] = useState<
    "initial" | "uploading" | "success" | "fail"
  >("initial");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setStatus("initial");
      setFile(e.target.files[0]);
      console.log('file')
      // console.log(typeof file)
    }
  };

  const handleUpload = async () => {
    // console.log('inside handle upload')
    if (file) {
        console.log(file)
        await axios.post('http://localhost:3001', {file: file}, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((data: any) => {
          console.log('data',data)
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    };
  
  return (
    <div className= "Upload-Page" style={{height: '1000px', width:'1000px'}}>
    <Navbar></Navbar>
    <div className="Upload-Components">
    <div className="FileUpload">
        <label>Upload File(s) Here</label>
        <input type= 'file' name='file' id='uploadPort' onChange={(e) => {handleFileChange(e)}}></input>
      </div>
    <div className="Upload">
      <Button variant="filled" onClick={() =>{
        handleUpload()
        }} id="uploadButton">Upload File</Button>
    </div>
    </div>
    </div>
  );
}

export default ButtonComponent;
