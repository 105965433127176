export const getMissingAssignments = (data: any) => {

  const missingAssignmentsList: any[] = [];

  const assignmentMissCounts: { [assignmentName: string]: number } = {};




  // Extract assignment names from the first array (index 0)

  const assignmentNames = data[0].slice(1); // Skip the first element (which is just the label "Assignment Name")




  // Iterate through each student (starting from index 3 onwards in the data)

  for (let i = 3; i < data.length; i++) {

    const studentData = data[i];

    const studentName = studentData[0]; // First element is the student's name

    const scores = studentData.slice(1); // Skip the first element (name) to get the scores

    const missingAssignments: string[] = [];




    // Iterate through the scores to find missing assignments (only treat '0' as missing)

    scores.forEach((score: string, index: number) => {

      if (score === '0') { // Only check for '0' as missing, ignore blank scores ('')

        const assignmentName = assignmentNames[index]; // Get the corresponding assignment name

        missingAssignments.push(assignmentName);




        // Increment the missing count for the assignment

        if (!assignmentMissCounts[assignmentName]) {

          assignmentMissCounts[assignmentName] = 0;

        }

        assignmentMissCounts[assignmentName]++;

      }

    });




    // If the student has any missing assignments, add them to the list

    if (missingAssignments.length > 0) {

      missingAssignmentsList.push({

        name: studentName,

        missingAssignments: missingAssignments,

      });

    }

  }




  console.log("Final missingAssignmentsList:", missingAssignmentsList); // Log the final list of missing assignments

  console.log("Final assignmentMissCounts:", assignmentMissCounts); // Log the final count of missing assignments




  return { missingAssignmentsList, assignmentMissCounts };

};