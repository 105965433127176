import { Route, Routes } from 'react-router-dom';
import NotFound from './NotFound';
import Upload from './Upload';
import TextComponent from './Text';
import ButtonComponent from './Buttons';
import Base from './Base';
import TeacherGoals from './TeacherGoals';
import PredectiveAnalysis from './PredictiveAnalysis';
import { AuthenticationForm } from './Authentication';
import MissingAssignments from './MissingAssignments';


const RouteSwitcher = () => {
  return (
    <Routes>
      <Route path="/Base" element={<Base />} />
      <Route path="/Upload" element={<Upload />} />
      <Route path="/text-component" element={<TextComponent />} />
      <Route path="/*" element={<AuthenticationForm />} />
      <Route path="/Analysis-Page" element={<ButtonComponent />} />
      <Route path="/Teacher-Goals" element={<TeacherGoals />} />
      <Route path="/Uploadto" element={<PredectiveAnalysis />} />
      <Route path="/Missing-Assignments" element={<MissingAssignments/>} />
    </Routes>
  );
};

export default RouteSwitcher;