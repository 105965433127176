import {Button} from '@mantine/core';


function TextComponent() {
  return (
    <div className="App">
      <Button variant="filled">TextComponent</Button>
    </div>
  );
}

export default TextComponent;
