
import React, { useEffect, useState } from "react";

import { useData } from "./dataContext";

import Navbar from "./Navbar";

import ExcelJS from "exceljs";

import { saveAs } from "file-saver";




interface StudentAssignment {

  name: string;

  missingAssignments: string[];

}




const MissingAssignments: React.FC = () => {

  const { data, getMissingAssignments } = useData();

  const [missingAssignments, setMissingAssignments] = useState<StudentAssignment[]>([]);

  const [assignmentMissCounts, setAssignmentMissCounts] = useState<{ [assignmentName: string]: number }>({});

  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);




  useEffect(() => {

    if (data && Array.isArray(data)) {

      const { missingAssignmentsList, assignmentMissCounts } = getMissingAssignments(data);

      setMissingAssignments(missingAssignmentsList);

      setAssignmentMissCounts(assignmentMissCounts);

    }

  }, [data]);




  const handleStudentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSelectedStudent(e.target.value);

  };




  const selectedStudentAssignments = missingAssignments.find(

    (student) => student.name === selectedStudent

  );




  const extractAssignmentName = (assignment: string) => {

    const parts = assignment.split(" - ");

    return parts.length > 0 ? parts[parts.length - 1] : assignment;

  };




  const handleExport = async () => {

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Missing Assignments");




    const headerStyle: Partial<ExcelJS.Style> = {

      font: { bold: true, color: { argb: "FFFFFFFF" } },

      fill: {

        type: "pattern",

        pattern: "solid",

        fgColor: { argb: "FF4F81BD" },

      },

      alignment: {

        vertical: "middle",

        horizontal: "center",

      },

      border: {

        top: { style: "thin" },

        left: { style: "thin" },

        bottom: { style: "thin" },

        right: { style: "thin" },

      },

    };




    const cellStyle: Partial<ExcelJS.Style> = {

      alignment: {

        vertical: "middle",

        horizontal: "center",

      },

      border: {

        top: { style: "thin" },

        left: { style: "thin" },

        bottom: { style: "thin" },

        right: { style: "thin" },

      },

    };




    const headerRow1 = worksheet.addRow(["Assignment Name", "Missing Count"]);

    headerRow1.eachCell((cell) => {

      cell.style = headerStyle;

    });




    Object.entries(assignmentMissCounts).forEach(([assignmentName, count], rowIndex) => {

      const row = worksheet.addRow([extractAssignmentName(assignmentName), count]);

      row.eachCell((cell, colIndex) => {

        cell.style = cellStyle;




        if (rowIndex % 2 === 0) {

          cell.fill = {

            type: "pattern",

            pattern: "solid",

            fgColor: { argb: "FFFAFAD2" },

          };

        }

      });

    });




    worksheet.addRow([]);




    const studentNames = missingAssignments.map((student) => student.name);

    const headerRow2 = worksheet.addRow(studentNames);

    headerRow2.eachCell((cell) => {

      cell.style = headerStyle;

    });




    const maxMissingAssignments = Math.max(...missingAssignments.map(student => student.missingAssignments.length));




    for (let i = 0; i < maxMissingAssignments; i++) {

      const row: (string | null)[] = missingAssignments.map(student => student.missingAssignments[i] ? extractAssignmentName(student.missingAssignments[i]) : null);

      const addedRow = worksheet.addRow(row);

      addedRow.eachCell((cell, colIndex) => {

        cell.style = cellStyle;




        if (i % 2 === 0) {

          cell.fill = {

            type: "pattern",

            pattern: "solid",

            fgColor: { argb: "FFFAFAD2" },

          };

        }

      });

    }




    worksheet.columns?.forEach(column => {

      let maxLength = 0;




      if (column.eachCell) {

        column.eachCell({ includeEmpty: true }, (cell) => {

          const cellValue = cell.value ? cell.value.toString() : '';

          maxLength = Math.max(maxLength, cellValue.length);

        });

        column.width = maxLength < 10 ? 10 : maxLength + 2;

      }

    });




    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), "MissingAssignments.xlsx");

  };




  return (

    <div

      style={{

        display: "flex",

        height: "100vh", // Full viewport height

        backgroundColor: "#333333", // Uniform background color for the whole page (same as header and navbar)

        color: "#FFFFFF", // Default font color for the page

      }}

    >

      {/* Navbar on the left side */}

      <div

        style={{

          width: "250px", // Fixed width for the navbar

          backgroundColor: "#333333", // Dark grey background for the navbar

          padding: "20px",

          position: "relative", // To ensure it's on the left and fixed

          color: "#FFFFFF", // Ensure text color is white inside the navbar

        }}

      >

        <Navbar /> {/* Your existing Navbar component */}

      </div>

  

      {/* Main content area */}

      <div

        style={{

          display: "flex",

          flexDirection: "column",

          flexGrow: 1,

          padding: "20px",

          backgroundColor: "#333333", // Dark grey background for the whole content area

          color: "#FFFFFF", // White font color for the content

          maxWidth: "1200px", // Set a fixed max width for larger screens

          margin: "0 auto", // Center the content horizontally

        }}

      >

        <h1

          style={{

            textAlign: "center",

            color: "#FFFFFF", // White text for the header

            backgroundColor: "#333333", // Dark grey header background

            padding: "20px",

            borderRadius: "8px",

            maxWidth: "100%", // Ensure header doesn't overflow

          }}

        >

          Missing Assignments Dashboard

        </h1>

  

        <div

          style={{

            display: "grid",

            gridTemplateColumns: "1fr 1fr", // Two equal columns

            gap: "20px",

            flexGrow: 1,

            overflowY: "auto", // Allow scrolling if content overflows vertically

            marginBottom: "15px", // Add space between content and export button

          }}

        >

          {/* Student Selector and Missing Assignments List */}

          <div

            style={{

              padding: "20px",

              border: "1px solid #ccc",

              backgroundColor: "#FFFFFF", // White background for the student section

              borderRadius: "8px",

              minWidth: "300px",

              maxWidth: "550px",

              width: "100%",

            }}

          >

            <h2 style={{ color: "#333333" }}>Select a Student</h2> {/* Ensure dark text */}

            <select

              onChange={handleStudentSelect}

              value={selectedStudent || ""}

              style={{

                width: "100%",

                padding: "8px",

                borderRadius: "5px",

                marginBottom: "10px",

                backgroundColor: "#FFFFFF",

                color: "#333333", // Dark text on light background

              }}

            >

              <option value="" disabled>

                Select a student

              </option>

              {missingAssignments.length > 0 ? (

                missingAssignments.map((student, index) => (

                  <option key={index} value={student.name}>

                    {student.name}

                  </option>

                ))

              ) : (

                <option disabled>No students with missing assignments</option>

              )}

            </select>

  

            {selectedStudent && selectedStudentAssignments ? (

              <div>

                <h3 style={{ color: "#333333" }}>Missing Assignments for {selectedStudent}</h3>

                <ul style={{ listStyle: "none", padding: 0 }}>

                  {selectedStudentAssignments.missingAssignments.map(

                    (assignment, index) => (

                      <li

                        key={index}

                        style={{

                          marginBottom: "5px",

                          color: "#333333", // Dark text for assignment names

                        }}

                      >

                        {extractAssignmentName(assignment)}

                      </li>

                    )

                  )}

                </ul>

              </div>

            ) : (

              <p style={{ color: "#333333" }}>Select a student to see their missing assignments</p>

            )}

          </div>

  

          {/* Assignment Missing Rates */}

          <div

            style={{

              padding: "20px",

              border: "1px solid #ccc",

              backgroundColor: "#FFFFFF", // White background for the table

              borderRadius: "8px",

              minWidth: "300px",

              maxWidth: "550px",

              width: "100%",

            }}

          >

            <h2 style={{ color: "#333333" }}>Missing Assignment Rates</h2>

            <table

              style={{

                width: "100%",

                borderCollapse: "collapse",

                color: "#333333", // Ensure dark text on light background

              }}

            >

              <thead>

                <tr>

                  <th

                    style={{

                      backgroundColor: "#4CAF50", // Green header background

                      color: "#FFFFFF", // White text for table headers

                      padding: "8px",

                      textAlign: "left",

                    }}

                  >

                    Assignment Name

                  </th>

                  <th

                    style={{

                      backgroundColor: "#4CAF50",

                      color: "#FFFFFF",

                      padding: "8px",

                      textAlign: "left",

                    }}

                  >

                    Missing Count

                  </th>

                </tr>

              </thead>

              <tbody>

                {Object.entries(assignmentMissCounts).map(

                  ([assignmentName, count], index) => (

                    <tr key={index}>

                      <td

                        style={{

                          padding: "8px",

                          backgroundColor: index % 2 === 0 ? "#f2f2f2" : "",

                        }}

                      >

                        {extractAssignmentName(assignmentName)}

                      </td>

                      <td

                        style={{

                          padding: "8px",

                          backgroundColor: index % 2 === 0 ? "#f2f2f2" : "",

                        }}

                      >

                        {count}

                      </td>

                    </tr>

                  )

                )}

              </tbody>

            </table>

          </div>

        </div>

  

        {/* Export Button */}

        <div

          style={{

            padding: "20px",

            display: "flex",

            justifyContent: "center",

            backgroundColor: "#333333", // Match background to the rest of the page

            marginTop: "auto", // Ensure it's at the bottom

          }}

        >

          <button

            onClick={handleExport}

            style={{

              padding: "10px 20px",

              backgroundColor: "#4CAF50", // Green button background

              color: "#FFFFFF", // White text on the button

              border: "none",

              borderRadius: "5px",

              fontSize: "16px",

              cursor: "pointer",

            }}

          >

            Export to Excel

          </button>

        </div>

      </div>

    </div>

  );

  

  

};

export default MissingAssignments;