import React, { createContext, useContext, useState, ReactNode } from 'react';

import { getMissingAssignments } from './helperFunctions'; // Import the updated helper function




// Define the structure of your data context

interface DataContextInterface {

  data: any;

  setData: (data: any) => void;

  getMissingAssignments: (data: any) => {

    missingAssignmentsList: { name: string, missingAssignments: string[] }[];

    assignmentMissCounts: { [assignmentName: string]: number };

  };

}




// Create the context with default value as null

const DataContext = createContext<DataContextInterface | null>(null);




// Custom hook to use the DataContext

export function useData() {

  const context = useContext(DataContext);

  if (!context) {

    throw new Error('useData must be used within a DataProvider');

  }

  return context;

}




// DataProvider component to provide context values

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [data, setData] = useState<any>([]); // Replace `any` with your actual data type




  const getMissingAssignmentsFromContext = (data: any) => {

    return getMissingAssignments(data); // Use the helper function, returning the missing assignments

  };




  return (

    <DataContext.Provider value={{ data, setData, getMissingAssignments: getMissingAssignmentsFromContext }}>

      {children}

    </DataContext.Provider>

  );

};