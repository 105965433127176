import {useDisclosure} from '@mantine/hooks';
// import Navbar from './components/Navbar';
import {
  AppShell,
} from '@mantine/core';
import './App.css';
import Header from './components/Header';
import RouterSwitcher from './components/RouterSwitcher';
import { DataProvider } from './components/dataContext';

function App() {
  const [opened, {toggle}] = useDisclosure();

  return (
    <div className="App" style={{marginTop: '20px'}}>
      <DataProvider>
      <AppShell
        header={{height: 60}}
        padding="md"
      >
        <Header toggle={toggle} opened={opened}/>
        {/* <Navbar /> */}
        <AppShell.Main>
          <RouterSwitcher />
        </AppShell.Main>
        <AppShell.Footer zIndex={opened ? 'auto' : 201} className= 'footer'>
          
        </AppShell.Footer>
      </AppShell>
      </DataProvider>
    </div>
  );
}

export default App;
