import { saveAs } from 'file-saver';

import ExcelJS from 'exceljs';




interface UseExcelExportProps {

  goalStatus: { name: string; averageScore: number; goalMet: boolean }[];

  filteredAssignmentNames: string[];

  studentScores: { [student: string]: number[] };

  goalPercentage: number;

  goalScore: number;

}




export function useExcelExport({

  goalStatus,

  filteredAssignmentNames,

  studentScores,

  goalPercentage,

  goalScore,

}: UseExcelExportProps) {

  const handleExport = async () => {

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('Student Goals');




    // Set Header Rows

    worksheet.addRow(['Goal Score:', `${goalScore.toFixed(2)}%`]);

    worksheet.addRow(['Goal Percentage of Students:', `${goalPercentage.toFixed(2)}%`]);

    worksheet.addRow([

      'Actual Percentage of Students Meeting Goal:',

      `${((goalStatus.filter(g => g.goalMet).length / goalStatus.length) * 100).toFixed(2)}%`,

    ]);

    worksheet.addRow([

      'Goal Met:',

      goalStatus.filter(g => g.goalMet).length / goalStatus.length * 100 >= goalPercentage ? 'Yes' : 'No',

    ]);

    worksheet.addRow([]); // Skip Row 5

    worksheet.addRow(['Student Name', ...filteredAssignmentNames, 'Average Score', 'Goal Status']); // Headers




    // Add Student Data Rows

    Object.entries(studentScores).forEach(([studentName, scores]) => {

      const averageScore = scores.length > 0

        ? (scores.reduce((a, b) => a + b, 0) / scores.length).toFixed(2)

        : '0.00';




      const goal = goalStatus.find(g => g.name === studentName);

      const goalMet = goal ? goal.goalMet : false;




      worksheet.addRow([

        studentName,

        ...filteredAssignmentNames.map((assignmentName, index) => scores[index] !== undefined ? scores[index].toFixed(2) : ''),

        averageScore,

        goalMet ? 'Met' : 'Not Met',

      ]);

    });




    // Apply Header Styling (for Row 6)

    worksheet.getRow(6).eachCell((cell) => {

      cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 12 };

      cell.fill = {

        type: 'pattern',

        pattern: 'solid',

        fgColor: { argb: 'FF4F81BD' }, // Blue background

      };

      cell.alignment = { horizontal: 'center', vertical: 'middle' };

    });




    // Apply Styling for Data Rows

    worksheet.eachRow((row, rowIndex) => {

      if (rowIndex >= 7) {

        const avgScoreCell = row.getCell(filteredAssignmentNames.length + 2); // Average Score Cell

        const goalStatusCell = row.getCell(filteredAssignmentNames.length + 3); // Goal Status Cell




        // Color-code "Goal Met" status

        goalStatusCell.font = {

          bold: true,

          color: { argb: goalStatusCell.value === 'Met' ? 'FF008000' : 'FFFF0000' }, // Green for "Met", Red for "Not Met"

        };




        // Apply gradient color for average score

        const avgScoreValue = parseFloat(avgScoreCell.value as string);

        avgScoreCell.fill = {

          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: avgScoreValue >= goalScore ? 'FF008000' : avgScoreValue < goalScore * 0.5 ? 'FFFF0000' : 'FFFFFF00' }, // Green, Red, Yellow

        };

      }

    });




    // Save the file

    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), 'Student_Goals.xlsx');

  };




  return { handleExport };

}