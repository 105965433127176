import {useDisclosure} from '@mantine/hooks';
import {
  AppShell,
} from '@mantine/core';
import '../App.css';
import Navbar from './Navbar';
import Header from './Header';
import RouterSwitcher from './RouterSwitcher';


function BasePage() {
  const [opened, {toggle}] = useDisclosure();

  return (
    <div className="Base" style={{marginTop: '20px'}}>
        
        <Header toggle={toggle} opened={opened}/>
        <Navbar />
        <AppShell.Main>
          <RouterSwitcher />
        </AppShell.Main>    </div>
  );
}

export default BasePage;
