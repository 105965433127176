import React, { CSSProperties, useContext } from 'react';
import { useCSVReader } from 'react-papaparse';
import {mathStuff} from './dataAnalysis/gpt'
import { useNavigate } from 'react-router-dom';
import {Button} from '@mantine/core';
import { useData } from './dataContext';



const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: '20%',
  } as CSSProperties,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } as CSSProperties,
};

export default function PredectiveAnalysis() {
  const navigate = useNavigate()

  const { CSVReader } = useCSVReader();
  const { data, setData, getMissingAssignments } = useData();

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        // console.log('beforemathstuff',results)
        console.log(mathStuff(results.data))
        setData(results.data)
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <>
          <div className="Upload-Actual" style={{width: '25%', height: '500px'}}>
            <button type='button' {...getRootProps()} style={{width: '100px', margin: '20px'}} className='Browse-File'>
              Browse files
            </button>
            <div className="acceptedFile" style={styles.acceptedFile}>
              Chosen File(
              {acceptedFile && acceptedFile.name})
            </div>
            <Button onClick={() =>{
        navigate('/Analysis-Page')}} className='proceedAnalysis'>
          Proceed to Analysis
        </Button>
          </div>
          <ProgressBar style={styles.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>
  );
}