import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ChartData, ChartOptions } from "chart.js";

// Register components
ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
  chartData: ChartData<'pie', number[], string>;
}

function PieChart({ chartData }: PieChartProps) {
  const options: ChartOptions<'pie'> = {
    plugins: {
      title: {
        display: true,
        text: "# of Assignments in Each Grade Category"
      }
    }
  };

  return (
    <div className="chart-container" style={{ width: '30%' }}>
      <h2 style={{ textAlign: "center" }}>Pie Chart</h2>
      <Pie
        data={chartData}
        options={options}
        style={{ alignSelf: 'center' }}
      />
    </div>
  );
}

export default PieChart;
