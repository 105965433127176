import { max, hexter, mean, sqrt, pow, std  } from 'mathjs'


// Raw Data Handling
export function mathStuff(rawData, selOptType, selOptUnit, selOptAssignmentName){
  console.log('rawdata',rawData)
  // Read the CSV file
      // Indices where number data starts

      
      const startj = 2, startk = 1;
      const nj = rawData.length;
      const nk = rawData[0].length;
      const rawNums = Array.from({ length: nj - startj }, () => Array(nk - startk).fill(NaN));
  
      for (let j = startj; j < nj; j++) {
        for (let k = startk; k < nk; k++) {
          try {
            // Attempt to convert to a float
            const value = parseFloat(rawData[j][k]);
            rawNums[j - startj][k - startk] = value;
          } catch (error) {
            // If conversion fails, skip the value
          }
        }
      }
  
      // Cleaning Data
  
      // Total Points as Vector setCurrentData(
      const tot = rawNums[0];
  
      // Student Scores as Matrix
      const score = rawNums.slice(1);
      for (let i = 0; i < score.length; i++) {
        for (let j = 0; j < score[i].length; j++) {
          if (score[i][j] === 0) score[i][j] = NaN;
        }
      }
  
      // Hexter as List
      const hexter = rawData[1].slice(1);
  
      // Assignment Types
      const type = rawData[0].slice(1);
  
          // Extract unique types
  
          const uniqueTypes = Array.from(new Set(type.map(item => item.split('-')[1])));
  
          // //Extract unique Units
      
          const uniqueUnits = Array.from(new Set(type.map(item => item.split('-')[0])));
      
          // //Extract unique assignment names
      
          const uniqueAssignmentNames = Array.from(new Set(type.map(item => item.split('-')[2])));
  
  
  
      // Replace zeros in Tot with the max value from the corresponding column in RawNums
      for (let j = 0; j < tot.length; j++) {
        if (tot[j] === 0) {
          tot[j] = max(...rawNums.map(row => row[j]));
        }
      }
  
      // Calculate Averages
      const njScore = score.length;
      const nkScore = score[0].length;
      const n = Array(tot.length).fill(0);
      const micro = Array(tot.length).fill(0);
      const sigma = Array(tot.length).fill(0);
  
      for (let k = 0; k < nkScore; k++) {
        const aveVec = score.map(row => row[k]).filter(val => !isNaN(val));
        n[k] = aveVec.length;
        if (n[k] > 0) {
          micro[k] = mean(aveVec);
          sigma[k] = std(aveVec, 'unbiased');
        }
      }
  
      // Average and Standard Deviation Percentage
      const microP = micro.map((val, idx) => val / tot[idx]);
      const sigmaP = sigma.map((val, idx) => val / tot[idx]);
  
      // User Input Section
      // const selOptType = [...uniqueTypes, "All"];
      // const selOptUnit = [...uniqueUnits, "All"];
      // const selOptAssignmentName = [...uniqueAssignmentNames, "All"];
  
      // // User Input Section
      // const selOptType = ["Quiz", "Test", "Lab", "Project", "DOL", "Participation", "Extra Credit", "All"];
      // const selOptUnit = ["Unit 1", "Unit 2", "Unit 3", "Unit 4", "Unit 5", "PSAT", "All"];
  
      // console.log("Choose an assignment type:");
      // selOptType.forEach((opt, idx) => console.log(`${idx + 1}. ${opt}`));
      // const selectType = readline.keyInSelect(selOptType, 'Select type:');
      // const selType = selOptType[selectType];
  
      // console.log("Choose a Unit:");
      // selOptUnit.forEach((opt, idx) => console.log(`${idx + 1}. ${opt}`));
      // const selectUnit = readline.keyInSelect(selOptUnit, 'Select unit:');
      // const selUnit = selOptUnit[selectUnit];
  
      // String Comparisons
  
      // // Type Sort
      const validType = Array(tot.length).fill(false);
      if (selOptType === "All") {
        validType.fill(true);
      } else {
        for (let k = 0; k < tot.length; k++) {
          if (type[k].includes(selOptType)) validType[k] = true;
        }
      }
  
      // Unit Sort
      const validUnit = Array(tot.length).fill(false);
      if (selOptUnit === "All") {
        validUnit.fill(true);
      } else {
        for (let k = 0; k < tot.length; k++) {
          if (type[k].includes(selOptUnit)) validUnit[k] = true;
        }
      }
  
      // // Combine Sort Lists
      const validMaster = validType.map((val, idx) => val && validUnit[idx]);
  
      // // Detect Invalid Search (No Results)
      // if (validMaster.every(val => !val)) {
      //   throw new Error('Error: No Search Results Found');
      // }
  
      // // Sorted Analytics
      const cutN = validMaster.filter(Boolean).length;
      const cutMicro = microP.filter((_, idx) => validMaster[idx]);
      const cutSigma = sigmaP.filter((_, idx) => validMaster[idx]);
      // const microMicro = mean(cutMicro);
      // const microSigma = mean(cutSigma);
  
      // Print Sorted Analytic Data
      // let meanPrint = "Mean Score for ";
      // let stdPrint = "Standard Deviation for ";
  
      // if (selType === "All" && selUnit === "All") {
      //   meanPrint += "All Assignment Types in All Units: ";
      //   stdPrint += "All Assignment Types in All Units: ";
      // } else if (selType === "All") {
      //   meanPrint += `All Assignment Types in ${selUnit}: `;
      //   stdPrint += `All Assignment Types in ${selUnit}: `;
      // } else if (selUnit === "All") {
      //   meanPrint += `${selType} in All Units: `;
      //   stdPrint += `${selType} in All Units: `;
      // } else {
      //   meanPrint += `${selType} in ${selUnit}: `;
      //   stdPrint += `${selType} in ${selUnit}: `;
      // }
  
      // meanPrint += `${(microMicro * 100).toFixed(2)}\n`;
      // stdPrint += `${(microSigma * 100).toFixed(2)}\n`;
  
      // console.log(meanPrint);
      // console.log(stdPrint);


          // Filter the columns based on Valid_Master
      let validIndices = validMaster.map((val, idx) => val === 1 ? idx : -1).filter(idx => idx !== -1);

      // Extract the columns of Score and Tot based on validIndices
      let SortScore = validIndices.map(idx => score.map(row => row[idx] / tot[idx]));

      // Flatten SortScore for easier processing
      let flattenedSortScore = [].concat(...SortScore);

      // Calculate the number of scores in each range
      let numA = flattenedSortScore.filter(score => score >= 0.9).length;
      let numB = flattenedSortScore.filter(score => score >= 0.8 && score < 0.9).length;
      let numC = flattenedSortScore.filter(score => score >= 0.7 && score < 0.8).length;
      let numD = flattenedSortScore.filter(score => score >= 0.65 && score < 0.7).length;
      let numF = flattenedSortScore.filter(score => score < 0.65).length;
      let numNAN = flattenedSortScore.filter(score => isNaN(score)).length;

      // Combine the results into an array
      let PieArray = [numA, numB, numC, numD, numF, numNAN];

      
      console.log('piearrau',PieArray)
      return PieArray
}