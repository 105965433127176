
import React, { useState, useMemo } from 'react';

import Navbar from "./Navbar";

import { useData } from './dataContext';

import { useExcelExport } from './useExcelExport';




const TeacherGoals: React.FC = () => {

  const { data } = useData();

  const [percOfStudents, setPercOfStudents] = useState<string>('50');

  const [goalScore, setGoalScore] = useState<string>('60');

  const [assType, setAssType] = useState<string>('tests');

  const [goal, setGoal] = useState<string>('');

  const [goalStatus, setGoalStatus] = useState<string>('');

  const [currentPercentage, setCurrentPercentage] = useState<number>(0);




  const assignmentTypes = useMemo<string[]>(() => {

    if (data.length > 0) {

      const types = data[0].slice(1).map((item: string) => {

        const parts = item.split('-');

        return parts[1]; 

      });

      return Array.from(new Set(types)); 

    }

    return [];

  }, [data]);




  const studentScores: { [student: string]: number[] } = useMemo(() => {

    const scores: { [student: string]: number[] } = {};

    if (data.length > 3) {

      const maxScores = data[2].slice(1).map((score: string) => parseFloat(score) || 0);

      const scoresData = data.slice(3); 




      scoresData.forEach((row: (string | number)[]) => {

        const [studentName, ...assignmentScores] = row;

        const validScores = assignmentScores

          .map((score, index) => {

            if (data[0][index + 1].includes(assType)) {

              const maxScore = maxScores[index];

              const percentage = maxScore > 0 ? (parseFloat(score as string) / maxScore) * 100 : 0;

              return isNaN(percentage) ? null : percentage;

            }

            return null;

          })

          .filter((score): score is number => score !== null);




        if (validScores.length > 0) {

          scores[studentName] = validScores;

        }

      });

    }

    return scores;

  }, [data, assType]);




  const getAssignmentNames = () => {

    if (data.length > 0) {

      return data[0].slice(1).map((item: string) => {

        const parts = item.split('-');

        return { name: parts[2], type: parts[1] }; 

      }).filter((assignment: { type: string; }) => assignment.type === assType); 

    }

    return [];

  };




  const updateTeacherGoals = () => {

    setGoal(`My goal this year is to have ${percOfStudents}% of my students achieve a ${goalScore}% or better on the assignment type: ${assType}.`);




    const perc = parseFloat(percOfStudents);

    const score = parseFloat(goalScore);




    const numOfStudentsMeetingGoal = Object.values(studentScores).filter(scores => {

      const averageScore = scores.reduce((acc, score) => acc + score, 0) / scores.length;

      return averageScore >= score;

    }).length;




    const totalStudents = Object.keys(studentScores).length;

    const percentageMeetingGoal = totalStudents > 0 ? (numOfStudentsMeetingGoal / totalStudents) * 100 : 0;

    setCurrentPercentage(parseFloat(percentageMeetingGoal.toFixed(2))); 




    if (percentageMeetingGoal >= perc) {

      setGoalStatus(`Congratulations! You are meeting the goal.`);

    } else {

      setGoalStatus(`Keep working! You are not meeting the goal yet.`);

    }

  };




  const assignmentNames = getAssignmentNames();




  const { handleExport } = useExcelExport({

    goalStatus: Object.entries(studentScores).map(([student, scores]) => ({

      name: student,

      averageScore: scores.length > 0 ? scores.reduce((acc, score) => acc + score, 0) / scores.length : 0,

      goalMet: scores.length > 0 && (scores.reduce((acc, score) => acc + score, 0) / scores.length) >= parseFloat(goalScore),

    })),

    filteredAssignmentNames: assignmentNames.map((a: { name: any; }) => a.name),

    studentScores,

    goalPercentage: parseFloat(percOfStudents),

    goalScore: parseFloat(goalScore),

  });




  return (

    <div style={{ 

      display: 'flex', // Flex layout for side-by-side positioning

      minHeight: '100vh', // Full viewport height

      width: '100%',

      backgroundColor: '#282c34', // Uniform background color across the page

      color: '#ffffff',

    }}>

      {/* Navbar on the left side */}

      <div style={{

        width: '250px', // Fixed width for the navbar

        backgroundColor: '#282c34', // Same background color as the rest of the page

        padding: '20px',

        boxSizing: 'border-box',

      }}>

        <Navbar />

      </div>

  

      {/* Main content area */}

      <div className="Teacher-Goals" style={{ 

        flex: 1, // Take up the remaining space

        color: '#ffffff',

        padding: '20px',

        boxSizing: 'border-box',

        display: 'flex',

        flexDirection: 'column',

      }}>

        <h1 style={{ color: '#ffffff', textAlign: 'center', margin: '0' }}>Teacher Goals</h1>

        <div style={{ 

          display: 'flex',

          flex: 1,

          marginTop: '20px',

          borderBottom: '2px solid #444',

          paddingBottom: '20px',

          boxSizing: 'border-box',

        }}>

          {/* Form section */}

          <div style={{ 

            flex: 1, 

            marginRight: '20px',

            boxSizing: 'border-box',

          }}>

            <form>

              <label htmlFor="percOfStudents" style={{ display: 'block', marginBottom: '10px' }}>Percentage of Students:</label>

              <input

                type="text"

                id="percOfStudents"

                value={percOfStudents}

                onChange={(e) => setPercOfStudents(e.target.value)}

                style={{ width: '100%', marginBottom: '20px', padding: '10px', backgroundColor: '#2c2f33', color: '#ffffff', border: '1px solid #444' }}

              />

  

              <label htmlFor="goalScore" style={{ display: 'block', marginBottom: '10px' }}>Goal Score (%):</label>

              <input

                type="text"

                id="goalScore"

                value={goalScore}

                onChange={(e) => setGoalScore(e.target.value)}

                style={{ width: '100%', marginBottom: '20px', padding: '10px', backgroundColor: '#2c2f33', color: '#ffffff', border: '1px solid #444' }}

              />

  

              <label htmlFor="assType" style={{ display: 'block', marginBottom: '10px' }}>Assignment Type:</label>

              <select

                id="assType"

                value={assType}

                onChange={(e) => setAssType(e.target.value)}

                style={{ width: '100%', marginBottom: '20px', padding: '10px', backgroundColor: '#2c2f33', color: '#ffffff', border: '1px solid #444' }}

              >

                {assignmentTypes.map((type, index) => (

                  <option key={index} value={type}>{type}</option>

                ))}

              </select>

      

              <button

                type="button"

                onClick={updateTeacherGoals}

                style={{ width: '100%', padding: '10px', backgroundColor: '#7289da', color: '#ffffff', border: 'none', cursor: 'pointer' }}

              >

                Update Goal

              </button>

            </form>

          </div>

      

          {/* Goal Status Section */}

          <div style={{ 

            flex: 1, 

            borderLeft: '2px solid #444', 

            paddingLeft: '20px',

            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',

          }}>

            <p style={{ color: '#ffffff', margin: '0' }}>{goal}</p>

            <p style={{ color: '#ffffff', margin: '0' }}>{goalStatus}</p>

            <p style={{ color: '#ffffff', margin: '0' }}>Percentage of Students Achieving Goal: {currentPercentage.toFixed(2)}%</p>

          </div>

  

        </div>

      

        {/* Export Button */}

        <button

          type="button"

          onClick={handleExport}  // Correct, no arguments needed

          style={{ 

            width: '50%', 

            padding: '10px', 

            backgroundColor: '#7289da', 

            color: '#ffffff', 

            border: 'none', 

            cursor: 'pointer', 

            marginTop: '20px',

            alignSelf: 'center', 

          }}

        >

          Export to Excel

        </button>

      </div>

    </div>

  );

  

  

};




export default TeacherGoals;