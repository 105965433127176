import { AppShell, NavLink } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <AppShell.Navbar p="md" z-index='1'>
      <NavLink
        label="Upload"
        onClick={() => navigate('/Uploadto')}
        style={{ margin: '5px' }}
      />
      <NavLink
        label="Analysis"
        onClick={() => navigate('/Analysis-Page')}
        style={{ margin: '5px' }}
      />
      <NavLink
        label="Teacher Goals"
        onClick={() =>navigate('/Teacher-Goals')}
        style={{ margin: '5px'}}
      />
      <NavLink
        label="Missing Assignments"
        onClick={() =>navigate('/Missing-Assignments')}
        style={{ margin: '5px'}}
      />
    </AppShell.Navbar>
  );
};

export default Navbar;